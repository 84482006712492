import { IForm } from '@.hooks';
import { StoreClusterFormRequest, StoreEditInfo } from '@/Services/api/methods/storeCluster/StoreClusterFormTypes';

import { ClusterNameForm, StoresForm } from './StoreClusterFormType';

interface GetFormData {
    formName: IForm<ClusterNameForm>;
    formStores: IForm<StoresForm>;
}

export const getFormData = ({ formStores, formName }: GetFormData): StoreClusterFormRequest | null => {
    let name: string | null = null;
    let stores: StoreEditInfo[] = [];

    formName.ifValid((data) => (name = data.name));
    formStores.ifValid((data) => {
        stores = Object.values(data);
    });

    if (!name || !stores.length) return null;

    return { name, stores };
};
