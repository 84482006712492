import { useMemo } from 'react';

export const useGetBreadCrumbs = (edit?: boolean) => {
    return useMemo(() => {
        return [
            { name: 'Analytics' },
            { name: 'Store clusters' },
            {
                name: edit ? 'Edit cluster' : 'Create new cluster',
                current: true,
            },
        ];
    }, [edit]);
};
