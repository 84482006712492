import React, { FC, useState } from 'react';

import { Bars } from '@.partials';
import { Filter3, FilterData } from '@.ui';
import { analyticsStoreSelectors } from '@/Store';
import { BestCombinationIngredientsStatistic } from '@/Types/analytics';

import S from './styles.module.scss';

interface BestCombinationIngredientsChartProps {
    data: BestCombinationIngredientsStatistic;
}

enum FilterType {
    AGE = 'age',
    SEX = 'sex',
}

export const BestCombinationIngredientsChart: FC<BestCombinationIngredientsChartProps> = ({ data }) => {
    const [ageIds, setAgeIds] = useState<number[]>([]);
    const [genderId, setGenderId] = useState<number | undefined>(-1);

    const filterBestCombinationIngredients = analyticsStoreSelectors.use.filterBestCombinationIngredients();

    const getFilterValues = (item: FilterData, filterType: FilterType) => {
        if (filterType === FilterType.AGE) {
            const newAgeIds = item.age_id && typeof item.age_id === 'string' ? item.age_id.split(',').map((id) => Number(id)) : undefined;

            if (!newAgeIds) {
                return { ageIds, genderId };
            }

            setAgeIds(newAgeIds);

            return {
                ageIds: newAgeIds,
                genderId,
            };
        }

        if (filterType === FilterType.SEX) {
            const newGenderId = item.sex_id ? Number(item.sex_id) : undefined;

            setGenderId(newGenderId);

            return { ageIds, genderId: newGenderId };
        }

        return { ageId: undefined, genderId: undefined };
    };

    const onChangeFilter = (item: FilterData, filterType: FilterType) => {
        filterBestCombinationIngredients(getFilterValues(item, filterType));
    };

    return (
        <div>
            <h3 className={S.title}>Best Combination of ingredients</h3>
            <div className={S.filtersWrapper}>
                <Filter3
                    className={S.filter}
                    onChange={(age) => onChangeFilter(age, FilterType.AGE)}
                    fromData={{
                        ages: data.ages,
                    }}
                    isSaveInQuery={false}
                    resetOptionName="All ages"
                >
                    <Filter3.Ages visibleLabel={''} visibleName="All ages" multiple />
                </Filter3>
                <Filter3
                    className={S.filter}
                    onChange={(sex) => onChangeFilter(sex, FilterType.SEX)}
                    fromData={{
                        sexes: data.genders,
                    }}
                    isSaveInQuery={false}
                    resetOptionName="All genders"
                >
                    <Filter3.Sexes visibleLabel={''} visibleName="All genders" />
                </Filter3>
            </div>

            <Bars data={data.dataset} isHorizontal axisDataName="Combinations" isHideFractionalTicks />
        </div>
    );
};
