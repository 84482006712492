import { StoreClusters } from 'src/Components/Pages/StoreClusters/StoreClusters';

import { AllCompaniesAnalyticsPage } from '@/Components/Pages/AllCompaniesAnalyticsPage';
import { AnalyticsPage } from '@/Components/Pages/AnalyticsPage';
import { RetailChainsPage } from '@/Components/Pages/RetailChainsPage';
import { RetailStoresPage } from '@/Components/Pages/RetailStoresPage';
import { StoreClustersEdit } from '@/Components/Pages/StoreClusters/StoreClusterEdit';
import { Routes } from '@/Routes/types';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';

export const AnalyticsRoutes: Routes = [
    {
        url: 'analytics',
        sidebar: {
            name: 'My Analytics',
            icon: 'analytics',
        },
        permissions: 'authorized',
        statisticPermissions: StatisticObjectEnum.STORE,
        children: [
            {
                url: 'all-companies',
                sidebar: {
                    name: 'All companies',
                },
                component: <AllCompaniesAnalyticsPage />,
                permissions: 'admin',
            },
            {
                url: 'brand',
                sidebar: {
                    name: 'My brand',
                },
                component: <AnalyticsPage />,
                permissions: 'notAdmin',
                statisticPermissions: StatisticObjectEnum.COMPANY,
            },
            {
                url: 'retail-chains',
                sidebar: {
                    name: 'My retail chains',
                },
                statisticPermissions: StatisticObjectEnum.STORE_GROUP,
                component: <RetailChainsPage />,
            },
            {
                url: 'stores',
                sidebar: {
                    name: 'My stores',
                },
                statisticPermissions: StatisticObjectEnum.STORE,
                component: <RetailStoresPage />,
            },
            {
                url: 'store-clusters',
                sidebar: {
                    name: 'Store cluster',
                },
                statisticPermissions: StatisticObjectEnum.STORE_CLUSTER,
                component: <StoreClusters />,
                permissions: 'admin',
                children: [
                    {
                        url: ':id',
                        component: <div>Id page</div>,
                        permissions: 'admin',
                        children: [
                            {
                                url: 'edit',
                                component: <StoreClustersEdit edit />,
                                permissions: 'admin',
                            },
                        ],
                    },
                    {
                        url: 'create',
                        component: <StoreClustersEdit />,
                        permissions: 'admin',
                    },
                ],
            },
            {
                url: 'details',
                component: <AnalyticsPage />,
            },
        ],
    },
];
