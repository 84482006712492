import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';

export class LinkHelper {
    public static login = '/login';
    public static passwordRecovery = '/password-recovery';
    public static passwordRecoverySuccess = `${this.passwordRecovery}/success`;
    public static passwordReset = '/reset-password';
    public static myPage = '/my-page';
    public static notFound = '/404';

    // Dashboard
    public static dashboard = '/dashboard';
    public static dashboardWizardAnswers = `${this.dashboard}/wizard-answers`;
    public static dashboardPopularProducts = `${this.dashboard}/popular-products`;
    public static dashboardPopularStores = `${this.dashboard}/popular-stores`;
    public static dashboardPopularSpecialOffers = `${this.dashboard}/popular-special-offers`;

    // Marketplace
    public static marketplace = '/marketplace';

    // Devices
    public static devices = '/devices';

    // Stations
    public static stations = '/stations';
    public static station = `${this.stations}/:id`;
    public static stationId = (id: number) => `${this.stations}/${id}`;
    public static stationsEdit = `${this.station}/edit`;
    public static stationsEditId = (id: number) => `${this.stationId(id)}/edit`;
    public static stationLogId = (station_id: number, log_id: number) => `${this.stationId(station_id)}/logs/${log_id}`;

    // Clients
    public static clients = '/clients';
    public static clientsCompanies = `${this.clients}/companies`;
    public static clientsCompany = `${this.clientsCompanies}/:id`;
    public static clientsCompanyId = (id: number) => `${this.clientsCompanies}/${id}`;
    public static clientsCompaniesAdd = `${this.clientsCompanies}/add`;
    public static clientsCompaniesEdit = `${this.clientsCompanies}/:id/edit`;
    public static clientsCompaniesEditId = (id: number) => `${this.clientsCompanies}/${id}/edit`;

    public static clientsStoreGroups = `${this.clients}/store-groups`;
    public static clientsStoreGroup = `${this.clientsStoreGroups}/:id`;
    public static clientsStoreGroupId = (id: number) => `${this.clientsStoreGroups}/${id}`;
    public static clientsStoreGroupsAdd = `${this.clientsStoreGroups}/add`;
    public static clientsStoreGroupsEdit = `${this.clientsStoreGroups}/:id/edit`;
    public static clientsStoreGroupsEditId = (id: number) => `${this.clientsStoreGroups}/${id}/edit`;

    public static clientsStores = `${this.clients}/stores`;
    public static clientsStore = `${this.clientsStores}/:id`;
    public static clientsStoreId = (id: number) => `${this.clientsStores}/${id}`;
    public static clientsStoresAdd = `${this.clientsStores}/add`;
    public static clientsStoresEdit = `${this.clientsStores}/:id/edit`;
    public static clientsStoresEditId = (id: number) => `${this.clientsStores}/${id}/edit`;

    // Orders
    public static orders = '/orders';
    //New
    public static ordersNew = `${this.orders}/new`;
    public static ordersNewItem = `${this.ordersNew}/:id`;
    public static ordersNewNew = `${this.ordersNew}/add`;
    public static ordersNewItemEdit = `${this.ordersNewItem}/edit`;
    public static ordersNewItemId = (id: string | number) => `${this.ordersNew}/${id}`;
    public static ordersNewItemEditId = (id: string | number) => `${this.ordersNewItemId(id)}/edit`;

    public static ordersDevices = `${this.orders}/devices`;
    public static ordersDevicesItem = `${this.ordersDevices}/:id`;
    public static ordersDevicesNew = `${this.ordersDevices}/add`;
    public static ordersDevicesItemEdit = `${this.ordersDevicesItem}/edit`;
    public static ordersDevicesItemId = (id: string | number) => `${this.ordersDevices}/${id}`;
    public static ordersDevicesItemEditId = (id: string | number) => `${this.ordersDevicesItemId(id)}/edit`;

    public static ordersHistory = `${this.orders}/history`;

    // Data
    public static data = '/data';
    public static dataFiles = `${this.data}/files-management`;
    public static dataFilesAdd = `${this.dataFiles}/add`;
    public static dataFilesEdit = `${this.dataFiles}/:id/edit`;
    public static dataFilesEditId = (id: number) => `${this.dataFiles}/${id}/edit`;

    public static updatePackages = `${this.data}/update-packages`;
    public static updatePackagesAdd = `${this.updatePackages}/add`;
    public static updatePackagesItem = `${this.updatePackages}/:id`;
    public static updatePackagesItemId = (id: string | number) => `${this.updatePackages}/${id}`;
    public static updatePackagesItemEdit = `${this.updatePackagesItemId}/edit`;
    public static updatePackagesItemEditId = (id: string | number) => `${this.updatePackagesItemId(id)}/edit`;

    public static dataProducts = `${this.data}/products`;
    public static dataProduct = `${this.dataProducts}/:id`;
    public static dataProductId = (id: number) => `${this.dataProducts}/${id}`;
    public static dataProductsAdd = `${this.dataProducts}/add`;
    public static dataProductEdit = `${this.dataProduct}/edit`;
    public static dataProductEditId = (id: number) => `${this.dataProducts}/${id}/edit`;
    public static dataProductPrices = `${this.dataProduct}/prices`;
    public static dataProductPricesId = (id: number) => `${this.dataProducts}/${id}/prices`;
    public static dataProductsImport = `${this.dataProducts}/import`;

    public static dataBrands = `${this.data}/brands`;
    public static dataBrand = `${this.dataBrands}/:id`;
    public static dataBrandId = (id: number) => `${this.dataBrands}/${id}`;
    public static dataBrandsAdd = `${this.dataBrands}/add`;
    public static dataBrandsEdit = `${this.dataBrand}/edit`;
    public static dataBrandsEditId = (id: number) => `${this.dataBrands}/${id}/edit`;

    public static dataIngredients = `${this.data}/ingredients`;
    public static dataIngredient = `${this.dataIngredients}/:id`;
    public static dataIngredientId = (id: number) => `${this.dataIngredients}/${id}`;
    public static dataIngredientsAdd = `${this.dataIngredients}/add`;
    public static dataIngredientsEdit = `${this.dataIngredient}/edit`;
    public static dataIngredientsEditId = (id: number) => `${this.dataIngredients}/${id}/edit`;

    public static dataCurrencies = `${this.data}/currencies`;
    public static dataCurrenciesAdd = `${this.dataCurrencies}/add`;
    public static dataCurrencyEdit = `${this.dataCurrencies}/:id/edit`;
    public static dataCurrencyEditId = (id: number) => `${this.dataCurrencies}/${id}/edit`;

    public static dataProductTypes = `${this.data}/products-types`;
    public static dataProductTypesAdd = `${this.dataProductTypes}/add`;
    public static dataProductTypeEdit = `${this.dataProductTypes}/:id/edit`;
    public static dataProductTypeEditId = (id: number) => `${this.dataProductTypes}/${id}/edit`;

    public static dataSpecialOffers = `${this.data}/special-offers`;
    public static dataSpecialOffer = `${this.dataSpecialOffers}/:id`;
    public static dataSpecialOfferId = (id: number) => `${this.dataSpecialOffers}/${id}`;
    public static dataSpecialOffersAdd = `${this.dataSpecialOffers}/add`;
    public static dataSpecialOfferEdit = `${this.dataSpecialOffer}/edit`;
    public static dataSpecialOfferEditId = (id: number) => `${this.dataSpecialOffers}/${id}/edit`;

    // Logs
    public static logs = '/logs';
    public static systemLogs = `${this.logs}/system-logs`;
    public static systemLogId = (id: number) => `${this.systemLogs}/${id}`;

    public static authLogs = `${this.logs}/auth-logs`;
    public static authLog = `${this.authLogs}/:id`;
    public static authLogId = (id: number) => `${this.authLogs}/${id}`;

    // Settings
    public static settings = '/settings';
    public static settingsUsers = `${this.settings}/users`;
    public static settingsUser = `${this.settingsUsers}/:id`;
    public static settingsUserId = (id: number) => `${this.settingsUsers}/${id}`;
    public static settingsUsersAdd = `${this.settingsUsers}/add`;
    public static settingsUsersEdit = `${this.settingsUsers}/:id/edit`;
    public static settingsUsersEditId = (id: number) => `${this.settingsUserId(id)}/edit`;

    public static settingsRoles = `${this.settings}/user-roles`;
    public static settingsRolesAdd = `${this.settingsRoles}/add`;
    public static settingsRolesEdit = `${this.settingsRoles}/:id/edit`;
    public static settingsRolesEditId = (id: number) => `${this.settingsRoles}/${id}/edit`;

    public static settingsIrcUsers = `${this.settings}/irc-users`;
    public static settingsIrcUser = `${this.settingsIrcUsers}/:id`;
    public static settingsIrcUserId = (id: number) => `${this.settingsIrcUsers}/${id}`;
    public static settingsIrcUsersAdd = `${this.settingsIrcUsers}/add`;
    public static settingsIrcUsersEdit = `${this.settingsIrcUsers}/:id/edit`;
    public static settingsIrcUsersEditId = (id: number) => `${this.settingsIrcUserId(id)}/edit`;

    // Analytics
    public static analyticsPage = `/analytics`;
    public static analyticsMyBrandPage = '/analytics/my-brand';
    public static analyticsRetailChainsPage = '/analytics/retail-chains';
    public static analyticsDetailsPage = (id: number | string, type: StatisticObjectEnum): string => `/analytics/details?id=${id}&type=${type}`;
    public static analyticsMyStoresPage = (storeGroupId?: number) =>
        storeGroupId ? `/analytics/stores?store_group_id=${storeGroupId}` : `${this.analyticsPage}/stores`;

    // product proposals
    public static productProposals = '/data/product-proposals';
    public static productProposalsCreate = '/data/product-proposals/create';
    public static productProposalsEdit = (id: string | number): string => `/data/product-proposals/${id}/edit`;
    public static productProposalsDetail = (id: string | number): string => `/data/product-proposals/${id}`;

    // Store clusters
    public static storeClusters = '/analytics/store-clusters';
    public static storeClusterCreate = '/analytics/store-clusters/create';
    public static storeClusterEdit = (id: string | number): string => `/analytics/store-clusters/${id}/edit`;
}
