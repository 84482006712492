import React, { FC, useEffect, useMemo } from 'react';

import { Select } from '@.ui';
import { analyticsStoreSelectors } from '@/Store';

import S from './styles.module.scss';

export const DESELECT_STATIONS_ID = -1;

export const AnalyticsPageSelectStation: FC = () => {
    const stations = analyticsStoreSelectors.use.stations();
    const statisticRequest = analyticsStoreSelectors.use.statisticRequest();
    const updatePageFilters = analyticsStoreSelectors.use.updatePageFilters();
    const loadStations = analyticsStoreSelectors.use.loadStations();

    const handleChange = (value: number | undefined) => {
        if (!statisticRequest.filters) {
            return;
        }

        if (value === DESELECT_STATIONS_ID) {
            updatePageFilters({ filters: { ...statisticRequest.filters, station_id: undefined } });
            return;
        }

        updatePageFilters({ filters: { ...statisticRequest.filters, station_id: value } });
    };

    const stationOptions = useMemo(
        () => [
            {
                key: 'reset',
                id: DESELECT_STATIONS_ID,
                name: 'All stations',
            },
            ...stations.map((station) => ({
                key: station.id,
                id: station.id,
                name: station.name,
            })),
        ],
        [stations]
    );

    const selectedStationId = statisticRequest.filters?.station_id;

    console.log('selectedStationId ', selectedStationId);

    useEffect(() => {
        loadStations();
    }, [loadStations, statisticRequest?.statistic_object?.id]);

    return (
        <div className={S.root}>
            <Select placeholder={'All stations'} theme={'white'} absolute value={selectedStationId} onChange={handleChange}>
                {stationOptions.map((stationItem) => (
                    <Select.Option key={stationItem.id} id={stationItem.id} isSelected={stationItem.id === selectedStationId} value={selectedStationId}>
                        {stationItem.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};
