import React, { FC, useMemo } from 'react';

import { FormFieldError } from '@.hooks';
import { Icon, Select } from '@.ui';
import { StoreEditInfo } from '@/Services/api/methods/storeCluster/StoreClusterFormTypes';
import { StationDictionary, StoresDictionary } from '@/Types/api/Dictionaries';

import S from './styles.module.scss';

interface ClusterProps {
    stores: StoresDictionary[];
    stations: StationDictionary[];
    onRemove: () => void;
    value?: Partial<StoreEditInfo>;
    onChange?: (value: Partial<StoreEditInfo> | undefined) => void;
    errors?: FormFieldError;
}
export const Cluster: FC<ClusterProps> = ({ stations, stores, onRemove, value, onChange, errors }) => {
    const sortedStationsByStore = useMemo(() => {
        const currentStoreId = value?.store_id;
        if (!currentStoreId) {
            return stations;
        }

        const sortedStations: StationDictionary[] = [];

        const anotherStations = stations.filter((station) => {
            if (station.store_id === currentStoreId) {
                sortedStations.push(station);
            }

            return station.store_id !== currentStoreId;
        });

        return [...sortedStations, ...anotherStations];
    }, [stations, value?.store_id]);

    return (
        <div className={S.root}>
            <Select
                value={value?.store_id}
                onChange={(store_id) => {
                    onChange?.({
                        store_id,
                        station_id: value?.station_id,
                    });
                }}
                placeholder={'Select store'}
                localSearch
                absolute
                errors={errors?.[0] ? [errors[0]] : undefined}
            >
                {stores.map((item) => (
                    <Select.Option key={item.id} id={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>

            <div className={S.trashWrapper}>
                <Select
                    value={value?.station_id}
                    onChange={(station_id) => {
                        onChange?.({
                            store_id: value?.store_id,
                            station_id,
                        });
                    }}
                    placeholder={'Select station'}
                    localSearch
                    absolute
                    errors={errors?.[1] ? [errors[1]] : undefined}
                >
                    {sortedStationsByStore.map((item) => (
                        <Select.Option key={item.id} id={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
                <Icon className={S.trash} name="trash" color={'brown'} onClick={onRemove} />
            </div>
        </div>
    );
};
