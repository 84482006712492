import cn from 'classnames';
import React, { FC } from 'react';

import { Buttons } from '@.partials';
import { Button } from '@.ui';

import S from './styles.module.scss';

interface ActionButtonsProps {
    edit?: boolean;
    handleUpdate: () => void;
    handleCreate: () => void;
    handleDelete?: () => void;
    className?: string;
}

export const ActionButtons: FC<ActionButtonsProps> = ({ className, edit, handleUpdate, handleCreate, handleDelete }) => {
    return (
        <>
            {edit ? (
                <div className={cn(S.buttons, className)}>
                    <Button className={S.button} onClick={handleDelete} color="brown" type="button">
                        Delete
                    </Button>
                    <Button className={S.button} onClick={handleUpdate} color="brown">
                        Save
                    </Button>
                </div>
            ) : (
                <Buttons text={'Add'} onClickSave={edit ? handleUpdate : handleCreate} className={cn(S.buttons, className)} />
            )}
        </>
    );
};
