import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Api } from '@.api';
import { useDictionaries, useForm } from '@.hooks';
import { Layout } from '@.layout';
import { Button, Input } from '@.ui';
import { isObject } from '@/Lib';
import { LinkHelper } from '@/Services';
import { StoreEditInfo } from '@/Services/api/methods/storeCluster/StoreClusterFormTypes';

import { ActionButtons } from './components/ActionButtons';
import { Cluster } from './components/Cluster';
import { ClusterNameForm, getFormData, StoresForm, useGetBreadCrumbs, useInitFormValues, validateForm } from './lib';
import S from './styles.module.scss';

interface StoreClusterEditProps {
    edit?: boolean;
}

export const StoreClustersEdit: FC<StoreClusterEditProps> = ({ edit }) => {
    const { id } = useParams();
    const navigator = useNavigate();

    const [fieldName, formName] = useForm<ClusterNameForm>();
    const [fieldStores, formStores] = useForm<StoresForm>();

    const [dictionaries] = useDictionaries('Stores', 'Stations');

    const breadcrumbs = useGetBreadCrumbs(edit);

    useInitFormValues({ formStores, formName, id, fieldStores });

    const handleUpdate = () => {
        const isFormValid = validateForm({ formStores, formName });

        if (isFormValid) {
            const data = getFormData({ formStores, formName });

            if (!data) return;

            Api.storeClusters()
                .updateStoreClusters(data, Number(id))
                .onSuccess(() => navigator(LinkHelper.storeClusters));
        }
    };
    const handleCreate = () => {
        const isFormValid = validateForm({ formStores, formName });

        if (isFormValid) {
            const data = getFormData({ formStores, formName });

            if (!data) return;

            Api.storeClusters()
                .createStoreClusters(data)
                .onSuccess(() => navigator(LinkHelper.storeClusters));
        }
    };

    const handleDelete = () => {
        Api.storeClusters()
            .removeStoreClusters(Number(id))
            .onSuccess(() => navigator(LinkHelper.storeClusters));
    };

    const handleAddCluster = () => {
        const uuid = uuidv4();

        fieldStores(uuid).value({
            uuid,
            store_id: null,
            station_id: null,
        });
    };

    const handleRemoveCluster = (uuid: string) => {
        const values = Object.entries(formStores.state);
        if (values.length === 1) {
            fieldStores(uuid).value(undefined);
            return;
        }

        const clearValues = values.map(([uuidItem, valueItem]) => {
            const safeValue = isObject(valueItem) ? valueItem : {};

            return [uuidItem, safeValue.value];
        });

        const filteredStores = clearValues.filter(([uuidItem]) => uuidItem !== uuid);

        formStores.reset.form();
        formStores.set.values(Object.fromEntries(filteredStores));
    };

    return (
        <Layout name="StoreClusterCreate" breadcrumbs={breadcrumbs} nobg>
            <h1>
                Store clusters
                <ActionButtons edit={edit} handleUpdate={handleUpdate} handleCreate={handleCreate} handleDelete={handleDelete} />
            </h1>
            <div className={S.content}>
                <div className={S.inputWrapper}>
                    <Input {...fieldName('name').register<string>()} className={S.input} text={'Store Clusters Name'} required />
                    <Button className={S.add} color="brown" text="Add" onClick={handleAddCluster} />
                </div>
                {Object.entries(formStores.state).map(([uuid]) => {
                    return (
                        <Cluster
                            {...fieldStores(uuid).register<Partial<StoreEditInfo>>()}
                            stores={dictionaries?.stores || []}
                            stations={dictionaries?.stations || []}
                            onRemove={() => handleRemoveCluster(uuid)}
                            key={uuid}
                        />
                    );
                })}
            </div>
        </Layout>
    );
};
