import React, { FC, useCallback, useEffect, useState } from 'react';

import { Api } from '@.api';
import { useStickyShadowHead } from '@.hooks';
import { Board } from '@.partials';
import { AnalyticsPageFilter } from '@/Components/Pages/AnalyticsPage/AnalyticsPageFilter';
import { AnalyticsPageSelectStation } from '@/Components/Pages/AnalyticsPage/AnalyticsPageSelectStation';
import { AnalyticsPageTariffsButtons } from '@/Components/Pages/AnalyticsPage/AnalyticsPageTariffsButtons';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import { analyticsStoreSelectors } from '@/Store';

import S from './styles.module.scss';

const useAnalyticsPageHeader = () => {
    const { statistic_object } = analyticsStoreSelectors.use.statisticRequest();

    const [pageTitle, setPageTitle] = useState<string>('My Analytics');

    const createPageTitle = useCallback((statisticObject: StatisticObjectEnum, objectName = '') => {
        const titlesMap: Record<string, string> = {
            [StatisticObjectEnum.STORE_GROUP]: 'Retail chain',
            [StatisticObjectEnum.STORE]: 'Retail store',
            [StatisticObjectEnum.COMPANY]: 'Company',
            [StatisticObjectEnum.STORE_CLUSTER]: 'Store Cluster',
        };

        return `My Analytics: ${titlesMap[statisticObject]} | ${objectName}`;
    }, []);

    /**
     * Update page title when open analytics.
     */
    useEffect(() => {
        if (!statistic_object) {
            return;
        }
        const { type, id } = statistic_object;

        switch (type) {
            case StatisticObjectEnum.STORE_GROUP:
                Api.store_groups()
                    .getById(Number(id))
                    .onSuccess((res) => {
                        setPageTitle(createPageTitle(type, res.data?.name));
                    });
                break;

            case StatisticObjectEnum.STORE:
                Api.stores()
                    .getById(Number(id))
                    .onSuccess((res) => {
                        setPageTitle(createPageTitle(type, res.data?.name));
                    });
                break;

            case StatisticObjectEnum.COMPANY:
                Api.companies()
                    .getById(Number(id))
                    .onSuccess((res) => {
                        setPageTitle(createPageTitle(type, res.data?.name));
                    });
                break;

            case StatisticObjectEnum.STORE_CLUSTER:
                Api.storeClusters()
                    .getStoreClusterById(Number(id))
                    .onSuccess((res) => {
                        setPageTitle(createPageTitle(type, res.data?.name));
                    });
                break;
            default:
                setPageTitle('My Analytics');
        }
    }, [createPageTitle, statistic_object]);

    return {
        pageTitle,
    };
};

export const AnalyticsPageHeader: FC = () => {
    const { refComponent } = useStickyShadowHead();

    const { statistic_object } = analyticsStoreSelectors.use.statisticRequest();
    const { pageTitle } = useAnalyticsPageHeader();

    return (
        <Board.Item head ref={refComponent}>
            <h1>{pageTitle}</h1>
            <div className={S.content}>
                {statistic_object?.type !== StatisticObjectEnum.STORE_CLUSTER && <AnalyticsPageSelectStation />}
                <AnalyticsPageTariffsButtons />
            </div>

            <AnalyticsPageFilter />
        </Board.Item>
    );
};
