export enum AnalyticWidgetsEnum {
    AGE = 'age',
    GENDER = 'gender',
    STRENGTH = 'strength',
    FRAGRANCE_TYPE = 'fragrance_type',
    WHIFF_COUNTS_PER_ITEM_BRAND = 'whiff_counts_per_item_brand',
    FAVORITE_HEART = 'favorite_heart',
    HOW_MANY_USERS_HAVE_COMPLETED_WIZARD = 'how_many_users_have_completed_wizard',
    CUSTOMERS_AND_WIZARD_STEPS = 'customers_and_wizard_steps',
    INGREDIENTS_BEST_COMBINATIONS = 'ingredients_best_combinations',
    PREFERABLE_PRODUCTS_STATISTIC = 'preferable_products',
}
