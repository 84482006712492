import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Api } from '@.api';
import { IField, IForm } from '@.hooks';
import { StoreClusterByIdResponse } from '@/Services/api/methods/storeCluster/StoreClusterByIdResponse';
import { StoreEditInfo } from '@/Services/api/methods/storeCluster/StoreClusterFormTypes';

import { ClusterNameForm, StoresForm } from './StoreClusterFormType';

interface UseInitFormValues {
    id?: number | string;
    formName: IForm<ClusterNameForm>;
    formStores: IForm<StoresForm>;
    fieldStores: IField<StoresForm>;
}

export const useInitFormValues = ({ id, formStores, formName, fieldStores }: UseInitFormValues) => {
    const [cluster, setCluster] = useState<StoreClusterByIdResponse>();

    useEffect(() => {
        if (id) {
            Api.storeClusters()
                .getStoreClusterById(Number(id))
                .onSuccess((res) => setCluster(res.data));
            return;
        }

        const uuid = uuidv4();

        fieldStores(uuid).value({
            uuid,
            stations: null,
            store: null,
        });
    }, [id]);

    useEffect(() => {
        if (cluster) {
            formName.set.values({
                name: cluster.name,
            });

            const stores = cluster.stores.map(({ store, station }) => {
                const uuid = uuidv4();

                const storeInfo: StoreEditInfo = {
                    store_id: store.id,
                    station_id: station.id,
                };

                return [uuid, storeInfo];
            });

            formStores.set.values(Object.fromEntries(stores));
        }
    }, [cluster]);
};
