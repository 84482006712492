import { Base } from '@.api/methods';
import { Request } from '@/Types';

import { StoreClusterByIdResponse } from './StoreClusterByIdResponse';
import { StoreClusterFormRequest } from './StoreClusterFormTypes';
import { StoreClusterResponse } from './StoreClusterResponse';

export class StoreClusterApiService extends Base {
    public getStoreClusters(query?: Request.All) {
        this.setPath('store-clusters');

        if (query) {
            this.setQuery(query);
        }

        return this.getRequest<StoreClusterResponse>();
    }

    public getStoreClusterById(id: number) {
        this.setPath('store-clusters');
        this.setId(id);

        return this.getRequest<StoreClusterByIdResponse>();
    }

    public createStoreClusters(data: StoreClusterFormRequest) {
        this.setPath('store-clusters');

        this.setData(data);

        return this.postRequest<StoreClusterByIdResponse>();
    }

    public updateStoreClusters(data: StoreClusterFormRequest, id: number) {
        this.setPath('store-clusters');

        this.setId(id);
        this.setData(data);

        return this.putRequest<StoreClusterByIdResponse>();
    }

    public removeStoreClusters(id: number) {
        this.setPath('store-clusters');

        this.setId(id);

        return this.deleteRequest();
    }
}
