import { DeviceOrders } from '@.api/methods';
import { AnalyticsApiService } from '@/Services/api/methods/analytics';
import { AuthLogs } from '@/Services/api/methods/authLogs';
import { Dashboard } from '@/Services/api/methods/dashboard';
import { ProductProposalApiService } from '@/Services/api/methods/ProductsProposals';
import { ProductTypes } from '@/Services/api/methods/productTypes';
import { StoreClusterApiService } from '@/Services/api/methods/storeCluster';
import { SystemLogs } from '@/Services/api/methods/systemLogs';

import {
    BaseResponseType,
    Brands,
    Companies,
    Currencies,
    Devices,
    Dictionaries,
    Export,
    Feedbacks,
    Files,
    functions,
    ImportBO,
    Ingredients,
    IrcUsers,
    Languages,
    ManagementFiles,
    Orders,
    Products,
    Roles,
    SpecialOffers,
    Stations,
    StoreGroups,
    Stores,
    UpdatePackages,
    User,
    Users,
} from './methods';
import { createResponse } from './modules';

export enum Code {
    Success = 200,
    Created = 201,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    ValidationError = 422,
    Error = 500,
    Local = 0,
}

export type IApiFn<T> = () => T;

/**
 * TODO: remove?
 */
export type ApiType = {
    dictionaries: IApiFn<Dictionaries>;
    user: IApiFn<User>;
    feedbacks: IApiFn<Feedbacks>;
    dashboard: IApiFn<Dashboard>;
    stations: IApiFn<Stations>;
    companies: IApiFn<Companies>;
    store_groups: IApiFn<StoreGroups>;
    stores: IApiFn<Stores>;
    management_files: IApiFn<ManagementFiles>;
    products: IApiFn<Products>;
    product_types: IApiFn<ProductTypes>;
    brands: IApiFn<Brands>;
    ingredients: IApiFn<Ingredients>;
    currencies: IApiFn<Currencies>;
    special_offers: IApiFn<SpecialOffers>;
    users: IApiFn<Users>;
    roles: IApiFn<Roles>;
    files: IApiFn<Files>;
    auth_logs: IApiFn<AuthLogs>;
    system_logs: IApiFn<SystemLogs>;
    import_bo: IApiFn<ImportBO>;
};

/**
 * TODO: need new repository service.
 */
export const Api = {
    dictionaries: () => new Dictionaries(),
    user: () => new User(),
    feedbacks: () => new Feedbacks(),
    dashboard: () => new Dashboard(),
    stations: () => new Stations(),
    companies: () => new Companies(),
    store_groups: () => new StoreGroups(),
    stores: () => new Stores(),
    managementFiles: () => new ManagementFiles(),
    products: () => new Products(),
    productsProposals: () => new ProductProposalApiService(),
    orders: () => new Orders(),
    deviceOrders: () => new DeviceOrders(),
    product_types: () => new ProductTypes(),
    brands: () => new Brands(),
    ingredients: () => new Ingredients(),
    currencies: () => new Currencies(),
    special_offers: () => new SpecialOffers(),
    users: () => new Users(),
    ircUsers: () => new IrcUsers(),
    roles: () => new Roles(),
    files: () => new Files(),
    export: () => new Export(),
    auth_logs: () => new AuthLogs(),
    system_logs: () => new SystemLogs(),
    languages: () => new Languages(),
    import_bo: () => new ImportBO(),
    devices: () => new Devices(),
    updatePackages: () => new UpdatePackages(),
    analytics: () => new AnalyticsApiService(),
    storeClusters: () => new StoreClusterApiService(),
    fake: <T>(data: T) =>
        createResponse(
            new Promise<BaseResponseType<T>>((resolve) =>
                resolve({
                    code: 200,
                    data,
                    message: '',
                })
            )
        ),
};

export type { functions };
