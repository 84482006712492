import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Button, Filter3 } from '@.ui';
import { LinkHelper } from '@/Services';
import { StoreClusterResponse } from '@/Services/api/methods/storeCluster/StoreClusterResponse';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import { Request } from '@/Types';

import S from './styles.module.scss';

export const StoreClusters: FC = () => {
    const navigator = useNavigate();
    const fetchData = useCallback((query: Request.All) => Api.storeClusters().getStoreClusters(query), []);

    const getData = useCallback((data: StoreClusterResponse) => {
        return data.store_clusters;
    }, []);

    return (
        <Layout name="StoreClustersPage" breadcrumbs={[{ name: 'Analytics' }, { name: 'Store clusters', current: true }]}>
            <h1>
                Store clusters
                <Button color="brown" text="Create new cluster" onClick={() => navigator(LinkHelper.storeClusterCreate)} />
            </h1>

            <Table2 get={fetchData} getData={getData} export="store-clusters">
                {{
                    filters: (handleChange) => (
                        <Filter3 onChange={handleChange} className={S.search}>
                            <Filter3.Search placeholder="Search by name of store cluster" />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Name of store cluster',
                            content: item.name,
                            sort: 'name',
                        }),
                        (item) => ({
                            name: 'Stores count',
                            content: item.stores_count,
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.storeClusterEdit(item.id),
                        }),
                        (item) => ({
                            type: 'analytics',
                            link: LinkHelper.analyticsDetailsPage(item.id, StatisticObjectEnum.STORE_CLUSTER),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
