import { IForm, requiredRule } from '@.hooks';
import { isObject } from '@/Lib';

import { ClusterNameForm, StoresForm } from './StoreClusterFormType';

interface IsFormValid {
    formName: IForm<ClusterNameForm>;
    formStores: IForm<StoresForm>;
}

export const validateForm = ({ formStores, formName }: IsFormValid) => {
    let isFormNameValid = false;
    let isFormStoresValid = true;

    formStores.reset.errors();
    formName.reset.errors();

    formName.ifValid(
        () => {
            isFormNameValid = true;
        },
        {
            name: {
                type: 'string',
                required: requiredRule,
                max: {
                    value: 1024,
                    message: 'Field must be up to 1024 characters',
                },
            },
        }
    );

    const formStoresValues = Object.entries(formStores.state);

    formStoresValues.forEach(([uuidItem, valueItem]) => {
        const safeValue = isObject(valueItem) ? valueItem : {};
        let storeError = '';
        let stationError = '';

        if (!safeValue.value?.store_id) {
            storeError = 'Field Store is required';
        }

        if (!safeValue.value?.station_id) {
            stationError = 'Field Station is required';
        }

        if (storeError || stationError) {
            isFormStoresValid = false;

            formStores.set.errors({ [uuidItem]: [storeError, stationError] });
        }
    });

    return isFormNameValid && isFormStoresValid;
};
