import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Api, Code } from '@.api';
import { createSelectors } from '@/Lib';
import { AnalyticsService } from '@/Services/Analytics';
import { StatisticRequest } from '@/Services/api/methods/analytics/StatisticTypes';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import {
    AnalyticWidgetsEnum,
    BestCombinationIngredientsStatistic,
    CustomersWizardStepsStatistic,
    DefaultStatistic,
    FavoriteHeartStatistic,
    PreferableProductsStatistic,
    WhiffCountersPerItemBrandStatistic,
} from '@/Types/analytics';
import { AnalyticsWidgetMap } from '@/Types/analytics/AnalyticsWidgetMap';
import { AnalyticsWidgetTabsEnum } from '@/Types/analytics/AnalyticsWidgetTabsEnum';
import { DictionaryItem } from '@/Types/api/Dictionaries';
import { Maybe } from '@/Types/Maybe';

export type Statistic = {
    [widget in AnalyticWidgetsEnum]?: Maybe<DefaultStatistic>;
} & {
    [AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND]?: Maybe<WhiffCountersPerItemBrandStatistic>;
    [AnalyticWidgetsEnum.FAVORITE_HEART]?: Maybe<FavoriteHeartStatistic>;
    [AnalyticWidgetsEnum.CUSTOMERS_AND_WIZARD_STEPS]?: Maybe<CustomersWizardStepsStatistic>;
    [AnalyticWidgetsEnum.INGREDIENTS_BEST_COMBINATIONS]?: BestCombinationIngredientsStatistic;
    [AnalyticWidgetsEnum.PREFERABLE_PRODUCTS_STATISTIC]?: PreferableProductsStatistic;
};

/**
 * TODO: maybe move async action to AnalyticService? Need thinking..
 */
export type AnalyticsState = {
    statistic: Statistic;
    statisticRequest: StatisticRequest;
    stations: DictionaryItem[];
};

export type AnalyticsActionStore = {
    loadStatistics: (filtersRequest?: StatisticRequest) => void;
    loadAllStatistics: () => void;
    loadUserStatistics: () => void;
    loadProductStatistics: () => void;
    loadStations: () => void;
    whiffFilterByBrand: (brandId: number | null) => void;
    filterFavoriteHeartByLimit: (limit: number) => void;
    filterBestCombinationIngredients: ({ ageId, genderId }: { ageId?: number; genderId?: number }) => void;
    filterPreferableProducts: ({ ageIds, genderId }: { ageIds?: number[]; genderId?: number }) => void;
    updatePageFilters: (filters: Partial<StatisticRequest>) => void;
    resetAllStatisticInfo: () => void;
};

export type AnalyticsStore = AnalyticsState & AnalyticsActionStore;

const initialState: AnalyticsState = {
    statistic: {},
    statisticRequest: {
        widgets: AnalyticsWidgetMap[AnalyticsWidgetTabsEnum.ALL],
        widget_filters: {
            [AnalyticWidgetsEnum.FAVORITE_HEART]: {
                limit: 10,
            },
        },
    },
    stations: [],
};

export const analyticsState = create<AnalyticsStore>()(
    immer<AnalyticsStore>((set, getState) => ({
        ...initialState,
        loadStatistics: async (statisticRequest = getState().statisticRequest) => {
            const service = new AnalyticsService();

            const response = await service.fetchStatistics(statisticRequest);

            set((state) => {
                state.statistic = response;
            });
        },
        loadAllStatistics: () => {
            const { statisticRequest, loadStatistics } = getState();

            const requestParameters: StatisticRequest = {
                ...statisticRequest,
                widgets: AnalyticsWidgetMap[AnalyticsWidgetTabsEnum.ALL],
            };

            set((state) => {
                state.statisticRequest = requestParameters;
            });

            loadStatistics(requestParameters);
        },
        loadStations: async (statisticRequest = getState().statisticRequest) => {
            if (!statisticRequest?.statistic_object?.id) {
                return;
            }

            const statisticObjectId = statisticRequest.statistic_object.id;

            const filtersByType: Partial<Record<StatisticObjectEnum, Record<string, string | number>>> = {
                [StatisticObjectEnum.STORE]: { store_id: statisticObjectId },
                [StatisticObjectEnum.COMPANY]: { company_id: statisticObjectId },
                [StatisticObjectEnum.STORE_GROUP]: { store_group_id: statisticObjectId },
            };

            const filter = filtersByType[statisticRequest.statistic_object.type] || filtersByType[StatisticObjectEnum.STORE];

            if (!filter) {
                return;
            }

            const response = await Api.dictionaries().byNames(['Stations'], {
                stations: filter,
            });

            if (response.code !== Code.Success) {
                return;
            }

            set((state) => {
                state.stations = response.data.stations || [];
            });
        },
        whiffFilterByBrand: async (brandId: number | null) => {
            const { statisticRequest } = getState();

            const queryParameters: StatisticRequest = {
                ...statisticRequest,
                widgets: [AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND],
                widget_filters: {},
            };

            if (brandId && brandId !== -1) {
                queryParameters.widget_filters = {
                    [AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND]: {
                        brand_id: brandId,
                    },
                };
            }

            set((state) => {
                state.statisticRequest.widget_filters[AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND] =
                    queryParameters.widget_filters[AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND];
            });

            const service = new AnalyticsService();

            const response = await service.fetchStatistics(queryParameters);

            set((state) => {
                state.statistic[AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND] = response[AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND];
            });
        },
        filterFavoriteHeartByLimit: async (limit: number) => {
            const { statisticRequest } = getState();

            const widgetFilters = {
                [AnalyticWidgetsEnum.FAVORITE_HEART]: {
                    limit,
                },
            };

            set((state) => {
                state.statisticRequest.widget_filters = widgetFilters;
            });

            const queryParameters: StatisticRequest = {
                ...statisticRequest,
                widgets: [AnalyticWidgetsEnum.FAVORITE_HEART],
                widget_filters: widgetFilters,
            };

            const service = new AnalyticsService();

            const response = await service.fetchStatistics(queryParameters);

            set((state) => {
                state.statistic[AnalyticWidgetsEnum.FAVORITE_HEART] = response[AnalyticWidgetsEnum.FAVORITE_HEART];
            });
        },
        filterBestCombinationIngredients: async ({ ageIds, genderId }: { ageIds?: number[]; genderId?: number }) => {
            const { statisticRequest } = getState();

            const queryParameters: StatisticRequest = {
                ...statisticRequest,
                widgets: [AnalyticWidgetsEnum.INGREDIENTS_BEST_COMBINATIONS],
                widget_filters: {},
            };

            queryParameters.widget_filters = {
                [AnalyticWidgetsEnum.INGREDIENTS_BEST_COMBINATIONS]: {
                    age: ageIds,
                    gender: genderId && genderId !== -1 ? [genderId] : undefined,
                },
            };

            set((state) => {
                state.statisticRequest.widget_filters[AnalyticWidgetsEnum.INGREDIENTS_BEST_COMBINATIONS] =
                    queryParameters.widget_filters[AnalyticWidgetsEnum.INGREDIENTS_BEST_COMBINATIONS];
            });

            const service = new AnalyticsService();

            const response = await service.fetchStatistics(queryParameters);

            set((state) => {
                state.statistic[AnalyticWidgetsEnum.INGREDIENTS_BEST_COMBINATIONS] = response[AnalyticWidgetsEnum.INGREDIENTS_BEST_COMBINATIONS];
            });
        },
        filterPreferableProducts: async ({ ageIds, genderId }: { ageIds?: number[]; genderId?: number }) => {
            const { statisticRequest } = getState();

            const queryParameters: StatisticRequest = {
                ...statisticRequest,
                widgets: [AnalyticWidgetsEnum.PREFERABLE_PRODUCTS_STATISTIC],
                widget_filters: {},
            };

            queryParameters.widget_filters = {
                [AnalyticWidgetsEnum.PREFERABLE_PRODUCTS_STATISTIC]: {
                    age: ageIds,
                    gender: genderId && genderId !== -1 ? [genderId] : undefined,
                },
            };

            set((state) => {
                state.statisticRequest.widget_filters[AnalyticWidgetsEnum.PREFERABLE_PRODUCTS_STATISTIC] =
                    queryParameters.widget_filters[AnalyticWidgetsEnum.PREFERABLE_PRODUCTS_STATISTIC];
            });

            const service = new AnalyticsService();

            const response = await service.fetchStatistics(queryParameters);

            set((state) => {
                state.statistic[AnalyticWidgetsEnum.PREFERABLE_PRODUCTS_STATISTIC] = response[AnalyticWidgetsEnum.PREFERABLE_PRODUCTS_STATISTIC];
            });
        },
        updatePageFilters: (filtersPayload: Partial<StatisticRequest>) => {
            const { statisticRequest } = getState();

            const newFilters = (() => {
                if (filtersPayload.filters) {
                    return {
                        ...statisticRequest.filters,
                        ...filtersPayload.filters,
                    };
                }

                return statisticRequest.filters;
            })();

            const filtersRequest: StatisticRequest = {
                ...statisticRequest,
                ...filtersPayload,
                filters: newFilters,
            };

            set((state) => {
                state.statisticRequest = filtersRequest;
            });
        },
        loadUserStatistics: () => {
            const { statisticRequest, loadStatistics } = getState();

            const requestParameters: StatisticRequest = {
                ...statisticRequest,
                widgets: AnalyticsWidgetMap[AnalyticsWidgetTabsEnum.USERS],
            };

            set((state) => {
                state.statisticRequest = requestParameters;
            });

            loadStatistics(requestParameters);
        },
        loadProductStatistics: () => {
            const { statisticRequest, loadStatistics } = getState();

            const requestParameters: StatisticRequest = {
                ...statisticRequest,
                widgets: AnalyticsWidgetMap[AnalyticsWidgetTabsEnum.PRODUCT],
            };

            set((state) => {
                state.statisticRequest = requestParameters;
            });

            loadStatistics(requestParameters);
        },
        resetAllStatisticInfo: () => {
            set((state) => {
                state.statistic = initialState.statistic;
                state.statisticRequest = initialState.statisticRequest;
            });
        },
    }))
);

export const analyticsStoreSelectors = createSelectors(analyticsState);
