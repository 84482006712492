import { Text } from '@visx/text';
import React, { FC, Fragment, useMemo } from 'react';

import { Donut, DonutLegendType } from '@.partials';
import { DEFAULT_ANALYTICS_CHART_COLORS } from '@/Components/Partials/Analytics/constants';
import { ChartDatasetValues } from '@/Types/analytics/chartsTypes';

import S from './styles.module.scss';

type FragranceType = {
    data: ChartDatasetValues;
};

export const FragranceTypeChart: FC<FragranceType> = ({ data }) => {
    const donutData = useMemo(
        () =>
            data.map((datasetItem, index) => ({
                value: datasetItem.value,
                label: datasetItem.label,
                color: DEFAULT_ANALYTICS_CHART_COLORS[index],
            })),
        [data]
    );

    const legend = useMemo<DonutLegendType>(
        () => ({
            columns: 2,
            legendItems: donutData.map(({ color, value, label }) => ({
                color,
                label: (
                    <Fragment key={label}>
                        <span>{label}</span>
                        <span className={S.label_value}>{value}</span>
                    </Fragment>
                ),
            })),
        }),
        [donutData]
    );

    const contentCenterData = useMemo(() => donutData.reduce((acc, obj) => acc + obj.value, 0), [donutData]);

    const ContentCenter = (
        <Text fontFamily="DM Sans" fontWeight={500} fontSize={20} lineHeight={26} fill="#000" textAnchor="middle" verticalAnchor="middle">
            {contentCenterData}
        </Text>
    );

    return (
        <div>
            <h3>Fragrance type</h3>
            <div className={S.fragrance_type}>
                <Donut data={donutData} legend={legend} contentCenter={ContentCenter} />
            </div>
        </div>
    );
};
