import { scaleLinear } from '@visx/scale';
import { useEffect, useMemo, useRef, useState } from 'react';

import { max } from '@/Services/Tools/numbers';

import { Axis } from '../Tools/Axis';
import { WithTooltip } from '../Tools/WithTooltip';
import { Bar } from './Bar';
import { BarDataItem, BarsProps } from './types';

const settings = {
    barMinWidth: 24,
    offset: 12,
    colors: {
        vertical: { color: '#BCCC80', hoverColor: '#A3CA74' },
        horisontal: { color: '#66C0BE', hoverColor: '#329795' },
    },
};

/**
 * TODO: decompose on vertical and horizontal bar chart.
 */
export const Bars = <BarsData extends BarDataItem = BarDataItem>({
    data,
    isHorizontal,
    name,
    sort = 'asc',
    height = 450,
    createTooltipData,
    axisDataName = 'Name',
    isHideFractionalTicks,
}: BarsProps<BarsData>) => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    /**
     * TODO: replace on <ParentSize/> from '@visx/responsive/lib/components/ParentSize';
     */
    const [width, setWidth] = useState<number>(0);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) {
            updateWidth();
        }
    }, [loaded]);

    const updateWidth = () => setWidth(svgRef.current?.clientWidth ?? 0);

    const sortMethod = useMemo(() => {
        switch (sort) {
            case 'asc':
                return (a: BarsData, b: BarsData) => b.value - a.value;
            case 'desc':
                return (a: BarsData, b: BarsData) => a.value - b.value;
        }
    }, [sort]);

    /**
     * TODO: maybe need remove maxItems?
     */
    const color = isHorizontal ? settings.colors.horisontal : settings.colors.vertical;

    const split = useMemo(() => {
        const t = [...data].sort(sortMethod);

        if (!isHorizontal) {
            t.reverse();
        }

        return t;
    }, [data, isHorizontal, sortMethod]);

    const NameComponent = <p className="chart_bars-label">{axisDataName}</p>;

    const [barScale, axisScale] = useMemo(() => {
        const size = isHorizontal ? width : height;

        const values = split.map((data) => data.value);

        const range = [0, max(values)];

        return [
            scaleLinear<number>({
                range: [size, 0],
                domain: range,
                round: true,
                nice: true,
            }),
            scaleLinear<number>({
                range: [size, 0],
                domain: range,
                nice: true,
                reverse: isHorizontal,
            }),
        ];
    }, [height, isHorizontal, split, width]);

    useEffect(() => {
        const handleResize = () => updateWidth();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="chart_bars">
            {isHorizontal && NameComponent}
            <WithTooltip color={color.color} name={name}>
                {(show, hide) => (
                    <svg
                        width="100%"
                        height={height}
                        ref={(ref) => {
                            setLoaded(true);
                            svgRef.current = ref;
                        }}
                    >
                        <Axis
                            scale={axisScale}
                            width={width}
                            height={height}
                            isHorizontal={isHorizontal}
                            labelStart={'Count'}
                            labelEnd={'Count'}
                            isHideFractionalTicks={isHideFractionalTicks}
                        >
                            <Bar
                                {...color}
                                data={split}
                                gap={settings.offset}
                                scale={barScale}
                                height={height}
                                width={width}
                                hideTooltip={hide}
                                showTooltip={show}
                                isHorizontal={isHorizontal}
                                createTooltipData={createTooltipData}
                            />
                        </Axis>
                    </svg>
                )}
            </WithTooltip>
            {!isHorizontal && NameComponent}
        </div>
    );
};
